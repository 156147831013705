import { combineReducers } from 'redux';
import { readMessageReducer } from './message/reducers';
import { readNotificationReducer } from './notification/reducers';
import authReducer from './authentication/reducers';
import ChangeLayoutMode from './themeLayout/reducers';
import { userReducer } from './users/reducers';
import { customersReducer } from './customers/reducers';
import { headerSearchReducer } from './headerSearch/reducers';
import { postListReducer } from './postList/reducers';
import { userPostListReducer } from './userPostList/reducers';
import { userProductListReducer } from './product/reducers';
import { followReducer } from './followTag/reducers';
import { questionListReducer, reportQuestionListReducer } from './questionList/reducers';

const rootReducers = combineReducers({
  headerSearchData: headerSearchReducer,
  message: readMessageReducer,
  notification: readNotificationReducer,
  users: userReducer,
  auth: authReducer,
  ChangeLayoutMode,
  customers: customersReducer,
  postList: postListReducer,
  userPostList:userPostListReducer,
  userProductList: userProductListReducer,
  follow: followReducer,
  questionsList:questionListReducer,
  reportData:reportQuestionListReducer
});

export default rootReducers;
