import actions from './actions';

const {
  QUESTION_LIST_BEGIN,
  QUESTION_LIST_SUCCESS,
  QUESTION_LIST_ERR,
  REPORT_QUESTION_LIST_BEGIN,
  REPORT_QUESTION_LIST_SUCCESS,
  REPORT_QUESTION_LIST_ERR
} = actions;

const initialState = {
  data: null,
  loading: true,
  error: null,
};

const questionListReducer = (state = initialState, action) => {
  const { type,data,err } = action;
  switch (type) {
    case QUESTION_LIST_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case QUESTION_LIST_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case QUESTION_LIST_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};

const reportQuestionListReducer = (state = initialState, action) => {
  const { type,data,err } = action;
  switch (type) {
    case REPORT_QUESTION_LIST_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case REPORT_QUESTION_LIST_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case REPORT_QUESTION_LIST_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};

export { questionListReducer ,reportQuestionListReducer};
