const actions = {
    QUESTION_LIST_BEGIN: 'QUESTION_LIST_BEGIN',
    QUESTION_LIST_SUCCESS: 'QUESTION_LIST_SUCCESS',
    QUESTION_LIST_ERR: 'QUESTION_LIST_ERR',
    REPORT_QUESTION_LIST_BEGIN: 'REPORT_QUESTION_LIST_BEGIN',
    REPORT_QUESTION_LIST_SUCCESS: 'REPORT_QUESTION_LIST_SUCCESS',
    REPORT_QUESTION_LIST_ERR: 'REPORT_QUESTION_LIST_ERR',
    
    
      questionListBegin: () => {
        return {
          type: actions.QUESTION_LIST_BEGIN,
        };
      },
    
      questionListSuccess: (data) => {
        return {
          type: actions.QUESTION_LIST_SUCCESS,
          data,
        };
      },
    
      questionListErr: (err) => {
        return {
          type: actions.QUESTION_LIST_ERR,
          err,
        };
      },
      reportQuestionListBegin: () => {
        return {
          type: actions.REPORT_QUESTION_LIST_BEGIN,
        };
      },
    
      reportQuestionListSuccess: (data) => {
        console.log("called data", data);
        
        return {
          type: actions.REPORT_QUESTION_LIST_SUCCESS,
          data,
        };
      },
    
      reportQuestionListErr: (err) => {
        return {
          type: actions.REPORT_QUESTION_LIST_ERR,
          err,
        };
      },
    
    };
    
    export default actions;
    