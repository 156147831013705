import React, { lazy } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

const question = lazy(() => import('../../container/pages/question'));
const SingleQuestion = lazy(() => import('../../container/pages/SingleQuestions'));
const ReportQuestions = lazy(() => import('../../container/pages/ReportQuestions'));

function PagesRoute() {
  const { path } = useRouteMatch();
  console.log(path,"paht in");
  
  return (
    <Switch>
      <Route path={`${path}/questionDetails`} component={SingleQuestion} />
      <Route path={`${path}/listing`} component={question} />
      <Route path={`${path}/report`} component={ReportQuestions} />
    </Switch>
  );
}

export default PagesRoute;
